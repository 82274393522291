import React from "react";
import "./App.css";
import RockMap from "./components/RockMap";

function App() {
  return (
    <RockMap />
  );
}

export default App;
